$(document).ready(function() {

	$('body').on('submit', '.js-validate-form', function(e) {
		if (e.isDefaultPrevented()) {
			// handle the invalid form...
		} else {
			$.ajax({
				type: "POST",
				url: $('.js-validate-form').attr('action'),
				data: $('.js-validate-form').serialize(),
				dataType: 'json',
				success: function(data) {
					if (data.status == "success") {
						$('.js-validate-form .msg-success').removeClass('hidden');
						$('.js-validate-form input:not(.js-validate-form--send)').val("");

						setTimeout(function() {
							$('.js-validate-form .msg-success').addClass('hidden');
						}, 3000);
					} else {

					}
				}
			});
		}
		return false;
	});

});
