$(document).ready(function() {
    $('img').each(function(index) {
        if (!$(this).attr('alt'))
        $(this).attr('alt', 'matara-'+index);
    })
    $('a').each(function(index) {
        if (!$(this).attr('title'))
        $(this).attr('title', 'matara-link-'+index);
    })
    $( document ).tooltip({
        items: "input, select, button",
        content: function() {
          var element = $( this );
          if ( element.is( "select" ) ) {
            return element.find('option[hidden]').text();
          }
          if ( element.is( "input" ) ) {
            return element.attr( "placeholder" );
          }
          if ( element.is( "[title]" ) ) {
            return element.attr( "title" );
          }
          if ( element.is( "[type='submit']" ) ) {
            return element.text();
          }
        }
      });

})